<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link class="text-decoration--none" :to="{ name: 'exercises' }">
          Exercise</router-link
        >
      </v-toolbar-title>
    </v-app-bar>

    <div class="ma-4">
      <div class="display-1 font-weight-bold my-5 text-capitalize">
        Add Exercise
      </div>

      <div class="col-xl-4 col-lg-6 col-md-12 mt-6 pa-0">
        <v-form @submit.prevent="submit" v-model="valid" ref="form">
          <v-divider class="pb-5"></v-divider>

          <label class="text-field-label">Name</label>
          <v-text-field
            flat
            solo
            required
            counter
            :rules="rules.name"
            class="mt-2"
            maxlength="255"
            v-model="form.name"
            placeholder="Name"
            :error-messages="form.$getError('name')"
          ></v-text-field>

          <label class="text-field-label">Description</label>
          <v-textarea
            solo
            maxlength="255"
            name="input-7-4"
            v-model="form.description"
            placeholder="Description"
            :error-messages="form.$getError('description')"
          ></v-textarea>

          <label class="text-field-label">Exercise Category</label>
          <v-select
            flat
            solo
            class="mt-2"
            aspect-ratio="1"
            :items="exercise_category_details"
            item-text="label"
            item-value="value"
            placeholder="Select Exercise Category"
            v-model="form.exercise_category_id"
          ></v-select>

          <label class="text-field-label">Exercise Items</label>
          <v-select
            flat
            solo
            v-model="form.exercise_items_id"
            :items="exercise_category_items"
            item-text="label"
            item-value="value"
            multiple
            chips
            placeholder="Select Exercise Items"
            persistent-hint
          ></v-select>

          <label class="text-field-label">Video</label>
          <v-select
            flat
            solo
            class="mt-2"
            aspect-ratio="1"
            :items="video_details"
            item-text="label"
            item-value="value"
            placeholder="Select Video"
            v-model="form.video_id"
          ></v-select>

          <label class="text-field-label">Thumbnail</label>
          <v-file-input
            :rules="rules.photo"
            accept="image/png, image/jpeg, image/bmp"
            placeholder="Pick a photo"
            :prepend-icon="icons.camera"
            v-model="form.photo"
            :error-messages="form.$getError('photo')"
          ></v-file-input>

          <label class="text-field-label">Before Photo</label>
          <v-file-input
            :rules="rules.before_photo"
            accept="image/png, image/jpeg, image/bmp"
            placeholder="Pick a before photo"
            :prepend-icon="icons.camera"
            v-model="form.before_photo"
            :error-messages="form.$getError('before_photo')"
          ></v-file-input>

          <label class="text-field-label">After Photo</label>
          <v-file-input
            :rules="rules.after_photo"
            accept="image/png, image/jpeg, image/bmp"
            placeholder="Pick a after photo"
            :prepend-icon="icons.camera"
            v-model="form.after_photo"
            :error-messages="form.$getError('after_photo')"
          ></v-file-input>

          <v-divider class="my-5"></v-divider>
          <div class="mt-4">
            <v-btn type="submit" color="primary" :loading="form.$busy"
              >Submit</v-btn
            >
          </div>
        </v-form>
      </div>
    </div>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Form from '@/utils/form'
import { mdiChevronLeft, mdiCamera } from '@mdi/js'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import { mapActions, mapState } from 'vuex'
import { each } from 'lodash'

export default {
  name: 'ExerciseCreate',

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      valid: false,
      photoCover: null,
      showUpload: false,
      form: new Form({
        name: '',
        description: '',
        exercise_category_id: null,
        video_id: null,
        photo: null,
        before_photo: null,
        after_photo: null,
        exercise_items_id: [],
      }),
      exercise_category_items: [],
      exercise_category_details: [],
      video_details: [],
      icons: {
        arrowLeft: mdiChevronLeft,
        camera: mdiCamera,
      },
      rules: {
        name: [(v) => !!v || 'Name is required'],
        video: [(v) => !!v || 'Video is required'],
        photo: [(v) => !!v || 'Photo is required'],
      },
      snackbar: {
        show: false,
        message: null,
        color: '',
      },
    }
  },

  computed: {
    ...mapState({
      exerciseDetails: (state) => state.exercise.exerciseDetails,
      exerciseItemCategory: (state) => state.exercise.exerciseItemCategory,
      video: (state) => state.video.list,
    }),
  },

  created() {
    this.getExerciseItemsCategory()
    this.getVideoDetails()
  },

  methods: {
    ...mapActions({
      storeExercise: 'exercise/storeExercise',
      getExerciseItemCategory: 'exercise/getExerciseItemCategory',
      getVideos: 'video/getVideos',
    }),

    async getExerciseItemsCategory() {
      this.loading = true
      await this.getExerciseItemCategory()
      each(this.exerciseItemCategory.exercise_category, (value, key) => {
        this.exercise_category_details.push({
          label: value.category_name,
          value: value.id,
        })
      })

      each(this.exerciseItemCategory.exercise_items, (value, key) => {
        this.exercise_category_items.push({
          label: value.name,
          value: value.id,
        })
      })
      this.loading = false
    },

    async getVideoDetails(page = 1) {
      let params = {
        page,
      }

      await this.getVideos(params)
      each(this.video, (value, key) => {
        this.video_details.push({
          label: value.name,
          value: value.id,
        })
      })
    },

    async submit() {
      this.form.$busy = true
      this.form.$clearErrors()

      const formData = new FormData()
      each(this.form.$data(), (value, key) => {
        if (['exercise_items_id'].includes(key)) {
          each(value, (v, k) => {
            formData.append('exercise_items_id[]', v)
          })
        } else {
          formData.append(key, value)
        }
      })

      this.storeExercise(formData)
        .then(() => {
          this.showSnackbar('Exercises successfully created!', 'success')

          setTimeout(() => {
            this.$router.push({
              name: 'exercises',
            })
          }, 1000)
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }

          this.form.$busy = false
        })
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },
  },
}
</script>
